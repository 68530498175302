import React, { useEffect, useState } from "react";
import { api } from "./../../../../services";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  Chip,
  IconButton,
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions,
  Grid
} from "@material-ui/core/";
import { useSnackbar } from "notistack";

import { CreateTwoTone as Create } from "@material-ui/icons/";

import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import ChipInput from "material-ui-chip-input";
import { withStyles } from "@material-ui/core/styles";

import { palette } from "./../../../../theme/palette";

const StyledButton = withStyles({
  chip: { backgroundColor: "#09f", color: "#fff" }
})(ChipInput);

export default function ListaQuestoes() {
  const user = JSON.parse(sessionStorage.getItem("@discursive_uninta/d_u"));
  const { enqueueSnackbar } = useSnackbar();
  const showMensage = (mensagem, variant = "success") => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(mensagem, { variant });
  };

  const [state, setState] = useState([]);

  const [dialogVisibleState, setDialogVisibleState] = useState(false);
  const [idQuestaoDialogState, setIdQuestaoDialogState] = useState("");
  const [nomeDisciplinaDialogState, setNomeDisciplinaDialogState] = useState(
    ""
  );
  const [idDisciplinaDialogState, setIdDisciplinaDialogState] = useState("");
  const [enunciadoDialogState, setEnunciadoDialogState] = useState("");
  const [palavrasChavesDialogState, setPalavrasChavesDialogState] = useState(
    []
  );

  useEffect(() => {
    updateQuestao();
  }, []);
  const updateQuestao = () => {
    if (user) {
      const { id_usuario } = user;
      api
        .post("/user/professor/find-questoes-by-user", {
          id_usuario
        })
        .then(response => {
          if (response.data) {
            const data_questao = response.data.map(questao => {
              const palavras_chave = questao?.palavras_chave;

              const Chips = palavras_chave.map((palavra, i) => {
                return (
                  <Chip
                    key={i}
                    label={palavra}
                    style={{
                      backgroundColor: `#037`,
                      color: "white",
                      marginRight: 8,
                      marginTop: 4
                    }}
                  />
                );
              });
              const ButtonUpdate = (
                <IconButton
                  style={{ backgroundColor: "#489F2A" }}
                  onClick={() => {
                    handleButtonUpdate(questao);
                  }}
                >
                  <Create fontSize="small" style={{ color: "#fff" }} />
                </IconButton>
              );
              return { ...questao, palavras_chave: Chips, ButtonUpdate };
            });
            setState(data_questao);
          }
        });
    }
  };
  const handleButtonUpdate = questao => {
    setIdQuestaoDialogState(questao.id_questao);
    setNomeDisciplinaDialogState(questao.nome_disciplina);
    setIdDisciplinaDialogState(questao.id_disciplina);
    setEnunciadoDialogState(questao.corpo_questao);
    setPalavrasChavesDialogState(questao.palavras_chave);
    setDialogVisibleState(true);
  };

  const onHide = () => {
    setDialogVisibleState(false);
  };
  const handleAtualiza = async () => {
    if (
      idQuestaoDialogState &&
      nomeDisciplinaDialogState &&
      idDisciplinaDialogState &&
      enunciadoDialogState &&
      palavrasChavesDialogState.length > 0
    ) {
      api
        .put("/questao/", {
          id_questao: idQuestaoDialogState,
          id_usuario: user.id_usuario,
          corpo_questao: enunciadoDialogState,
          nome_disciplina: nomeDisciplinaDialogState,
          id_disciplina: idDisciplinaDialogState,
          palavras_chave: palavrasChavesDialogState
        })
        .then(response => {
          if (response.data) {
            showMensage(response.data.success);
            setIdQuestaoDialogState("");
            setNomeDisciplinaDialogState("");
            setIdDisciplinaDialogState("");
            setEnunciadoDialogState("");
            setPalavrasChavesDialogState([]);
            setDialogVisibleState(false);
            updateQuestao();
          } else {
            showMensage("Erro ao atualizar", "error");
          }
        })
        .catch(() => {
          showMensage("Erro ao atualizar", "error");
        });
    } else {
      showMensage("Erro ao atualizar", "error");
    }
  };

  const handleAddChip = chip => {
    setPalavrasChavesDialogState([...palavrasChavesDialogState, chip]);
  };

  const handleDeleteChip = chip => {
    let keyWords = palavrasChavesDialogState.filter(item => {
      return item !== chip;
    });
    setPalavrasChavesDialogState(keyWords);
  };

  return (
    <React.Fragment>
      <DataTable className="card" style={{ margin: 16 }} value={state}>
        <Column
          style={{ textAlign: "center", width: "15%" }}
          filterPlaceholder="Pesquise aqui"
          filter
          field="id_disciplina"
          sortable
          header="ID Disc."
        />
        <Column
          style={{ textAlign: "center" }}
          filterPlaceholder="Pesquise aqui"
          filter
          field="nome_disciplina"
          sortable
          header="Disciplina"
        />

        <Column
          style={{ textAlign: "center" }}
          field="corpo_questao"
          sortable
          header="Enunciado"
        />
        <Column
          style={{ textAlign: "center" }}
          field="palavras_chave"
          header="Conceitos"
        />
        <Column
          style={{ textAlign: "center", width: "15%" }}
          field="ButtonUpdate"
          header="Ação"
        />
      </DataTable>

      <Dialog
        fullWidth
        style={{ textAlign: "center" }}
        open={dialogVisibleState}
        onClose={() => onHide()}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#eee"
          }}
        >
          <h5>Atualização de Questão</h5>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ textAlign: "left" }}>
            <Grid xs={12} md={3} item>
              <label
                style={{ fontWeight: 700, color: "#555" }}
                htmlFor="id_disciplina"
              >
                Id da disciplina:
              </label>
              <InputText
                id="id_disciplina"
                value={idDisciplinaDialogState}
                onChange={e => setIdDisciplinaDialogState(e.target.value)}
              />
            </Grid>
            <Grid xs={12} md={9} item>
              <label
                style={{ fontWeight: 700, color: "#555" }}
                htmlFor="nome_disciplina"
              >
                Nome da disciplina:
              </label>
              <InputText
                id="nome_disciplina"
                value={nomeDisciplinaDialogState}
                onChange={e => setNomeDisciplinaDialogState(e.target.value)}
              />
            </Grid>

            <Grid xs={12} item>
              <label
                style={{ fontWeight: 700, color: "#555" }}
                htmlFor="textArea"
              >
                Enunciado da questão:
              </label>
              <InputTextarea
                autoResize={false}
                row={12}
                id="textArea"
                value={enunciadoDialogState}
                onChange={e => setEnunciadoDialogState(e.target.value)}
              />
            </Grid>

            <div className="row" style={{ marginTop: 16 }}>
              <span style={{ display: "flex", flexDirection: "column" }}>
                <label
                  style={{ fontWeight: 700, color: "#555" }}
                  htmlFor="chip"
                >
                  Digite os conceitos que deverão ser abordados na atividade
                  proposta ( separe com <b>";"</b> )
                </label>
                <StyledButton
                  id="chip"
                  style={{
                    paddingTop: 16,
                    paddingLeft: 8,
                    paddingRight: 8,
                    backgroundColor: "#eee",
                    borderRadius: 10
                  }}
                  newChipKeys={[";", "Enter"]}
                  value={palavrasChavesDialogState}
                  onAdd={chip => handleAddChip(chip)}
                  onDelete={chip => handleDeleteChip(chip)}
                  placeholder='Separe com ";"'
                />
              </span>
            </div>
          </Grid>
        </DialogContent>
        <DialogActions>
          <div>
            <Button onClick={onHide} variant="outlined" color="secondary">
              Cancelar
            </Button>
            <Button
              onClick={handleAtualiza}
              variant="contained"
              style={{ backgroundColor: "#0a0", color: "white", marginLeft: 8 }}
            >
              Salvar
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
