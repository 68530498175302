import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Button from "@material-ui/core/Button";
import { api } from "./../../services/";

const BASE_URL = "https://analiseatividade.uninta.edu.br/aluno/sig-in/";
export default function SiginAva() {
  const [state, setState] = useState([]);
  useEffect(() => {
    api.get("/user/ava/find-all-questoes").then(response => {
      if (response) {
        if (response.data) {
          const questaoAndNome = response.data.map(questao => {
            const { id_questao, ...rest } = questao;
            const ButtonCopy = (
              <Button
                variant="outlined"
                className="btn-aluno"
                style={{ color: "#009" }}
                onClick={async e => {
                  await navigator.clipboard.writeText(BASE_URL + id_questao);
                }}
                children="Copy"
              />
            );
            return { ButtonCopy, ...rest };
          });
          setState(questaoAndNome);
          console.log(questaoAndNome);
        }
      }
    });
  }, []);
  return (
    <React.Fragment>
      <DataTable
        paginator
        rows={15}
        className="card"
        style={{ margin: 16 }}
        value={state}
      >
        <Column
          style={{ textAlign: "center" }}
          filterPlaceholder="Pesquise aqui"
          filter
          field="id_disciplina"
          header="ID Disciplina"
        />
        <Column
          style={{ textAlign: "center" }}
          filterPlaceholder="Pesquise aqui"
          filter
          field="nome_disciplina"
          sortable
          header="Nome Disciplina"
        />

        <Column
          style={{ textAlign: "center" }}
          filterPlaceholder="Pesquise aqui"
          filter
          field="nome"
          sortable
          header="Professor"
        />
        <Column
          style={{ textAlign: "center", width: "15%" }}
          field="ButtonCopy"
          header="Link"
        />
      </DataTable>
    </React.Fragment>
  );
}
