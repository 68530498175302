import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
if (process.env.NODE_ENV === "production") {
  console.error = () => {};
  console.log = () => {};
  console.warn = () => {};
}
ReactDOM.render(<App />, document.getElementById("root"));
