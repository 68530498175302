import React, { useEffect, useState } from 'react';
import { api } from './../../../../services';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
	Card,
	CardContent,
	Button,
	DialogContent,
	Dialog,
	DialogTitle,
	DialogActions,
	IconButton
} from '@material-ui/core/';
import { Visibility } from '@material-ui/icons/';

import parse from 'html-react-parser';

export default function ListagemAbaixoDaMedia() {
	const user = JSON.parse(sessionStorage.getItem('@discursive_uninta/d_u'));

	const [ state, setState ] = useState([]);
	const [ modal, setModal ] = useState({
		open: false,
		dataShow: {}
	});

	useEffect(() => {
		updateQuestao();
	}, []);
	const updateQuestao = () => {
		if (user) {
			api.get('/user/tutor/find-all-submetidos').then((response) => {
				if (response.data) {
					const trabalhosSubmetidos = response.data.map((trabSubmetido) => {
						return {
							...trabSubmetido,
							showModalButton: (
								<IconButton
									style={{ backgroundColor: '#aa0000aa' }}
									onClick={() => {
										setModal({
											...modal,
											open: true,
											dataShow: trabSubmetido
										});
									}}
								>
									<Visibility htmlColor="#fff" />
								</IconButton>
							)
						};
					});
					const filtrado = trabalhosSubmetidos.filter((item) => {
						return item.porcentagem_acertos < 70;
					});
					console.log(filtrado);
					setState(filtrado);
				}
			});
		}
	};
	return (
		<React.Fragment>
			<DataTable paginator rows={15} className="card" style={{ margin: 16 }} value={state}>
				<Column
					style={{ textAlign: 'center' }}
					filterPlaceholder="Pesquise aqui"
					filter
					field="ra_aluno"
					header="RA"
				/>
				<Column
					style={{ textAlign: 'center' }}
					filterPlaceholder="Pesquise aqui"
					filter
					field="nome_aluno"
					sortable
					header="Nome Aluno"
				/>

				<Column
					style={{ textAlign: 'center' }}
					filterPlaceholder="Pesquise aqui"
					filter
					field="nome_disciplina"
					sortable
					header="Nome Disciplina"
				/>
				<Column style={{ textAlign: 'center' }} field="porcentagem_acertos" sortable header="% de Acertos" />
				<Column style={{ textAlign: 'center', width: '15%' }} field="showModalButton" header="Ação" />
			</DataTable>
			<Dialog
				fullWidth
				style={{ textAlign: 'center' }}
				open={modal.open}
				onClose={() => setModal({ ...modal, open: false })}
			>
				<DialogTitle
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: '#eee'
					}}
				>
					<p>Resolução do aluno </p>
					<p>{modal.dataShow.nome_aluno}</p>
				</DialogTitle>
				<DialogContent style={{ textAlign: 'justify' }}>
					<Card style={{ margin: 16 }}>
						<CardContent>
							<h5>Enunciado:</h5>
							<h6>{modal.dataShow.corpo_questao}</h6>
						</CardContent>
					</Card>
					<Card style={{ margin: 16 }}>
						<CardContent>
							<h5>Resumo:</h5>
							<div style={{ fontStyle: 'italic' }}>
								{parse(modal.dataShow.resumo ? modal.dataShow.resumo : '')}
							</div>
						</CardContent>
					</Card>
					<Card style={{ margin: 16 }}>
						<CardContent>
							<h5>Introdução:</h5>
							<div style={{ fontStyle: 'italic' }}>
								{parse(modal.dataShow.introducao ? modal.dataShow.introducao : '')}
							</div>
						</CardContent>
					</Card>
					<Card style={{ margin: 16 }}>
						<CardContent>
							<h5>Desenvolvimento:</h5>
							<div style={{ fontStyle: 'italic' }}>
								{parse(modal.dataShow.desenvolvimento ? modal.dataShow.desenvolvimento : '')}
							</div>
						</CardContent>
					</Card>
					<Card style={{ margin: 16 }}>
						<CardContent>
							<h5>Consideracoes Finais:</h5>
							<div style={{ fontStyle: 'italic' }}>
								{parse(modal.dataShow.consideracoes_finais ? modal.dataShow.consideracoes_finais : '')}
							</div>
						</CardContent>
					</Card>
					<Card style={{ margin: 16 }}>
						<CardContent>
							<h5>Referências:</h5>
							<div style={{ fontStyle: 'italic' }}>
								{parse(modal.dataShow.referencias ? modal.dataShow.referencias : '')}
							</div>
						</CardContent>
					</Card>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setModal({ ...modal, open: false })}
						variant="contained"
						color="primary"
						size="small"
						style={{ margin: 8 }}
					>
						Fechar
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}
