import React, { useEffect, useState } from "react";
import { api } from "./../../../../services";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  Card,
  CardContent,
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton
} from "@material-ui/core/";
import { FileCopy, Visibility } from "@material-ui/icons/";
import { useSnackbar } from "notistack";

import ChipInput from "material-ui-chip-input";
import { withStyles } from "@material-ui/core/styles";
import parse from "html-react-parser";
import PlagiumItem from "../../../Aluno/pages/RepondeQuestao/components/PlagiumItem";

const StyledButton = withStyles({
  chip: {
    backgroundColor: "#09f",
    fontWeight: 500,
    fontSize: "12pt",
    color: "#fff"
  }
})(ChipInput);

export default function ListagemGeral() {
  const user = JSON.parse(sessionStorage.getItem("@discursive_uninta/d_u"));
  const { enqueueSnackbar } = useSnackbar();
  const showMensage = (mensagem, variant = "success") => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(mensagem, { variant });
  };

  const [state, setState] = useState([]);
  const [openPlagio, setOpenPlagio] = useState(false);
  const [resultPlagio, setResultPlagio] = useState({ results: [] });
  const [modal, setModal] = useState({
    open: false,
    dataShow: {}
  });

  useEffect(() => {
    updateQuestao();
  }, []);
  const updateQuestao = () => {
    if (user) {
      api.get("/user/tutor/find-all-submetidos").then(response => {
        let x = [];
        if (response.data) {
          const trabalhosSubmetidos = response.data.map(trabSubmetido => {
            return {
              ...trabSubmetido,
              score_plagio:
                trabSubmetido.score_plagio !== null
                  ? `${trabSubmetido.score_plagio}%`
                  : "NÃO ANALISADO",
              showModalButton: (
                <>
                  <IconButton
                    style={{ backgroundColor: "#0055aa" }}
                    onClick={() => {
                      setModal({
                        ...modal,
                        open: true,
                        dataShow: trabSubmetido
                      });
                    }}
                  >
                    <Visibility htmlColor="#fff" />
                  </IconButton>
                  {trabSubmetido.id_plagio && (
                    <IconButton
                      style={{ backgroundColor: "#aa0000aa", marginLeft: 8 }}
                      onClick={async () => {
                        const plagio = await api.post("aluno/get-plagio", {
                          id: trabSubmetido.id_plagio
                        });
                        console.log(plagio.data);
                        setResultPlagio(plagio.data);
                        setOpenPlagio(true);
                      }}
                    >
                      <FileCopy htmlColor="#fff" />
                    </IconButton>
                  )}
                </>
              )
            };
          });
          Promise.all(trabalhosSubmetidos).then(completed => {
            setState(completed);
          });
        }
      });
    }
  };
  if (state.length > 0) console.log(state[0].corpo_questao);
  return (
    <React.Fragment>
      <DataTable
        paginator
        rows={15}
        className="card"
        style={{ margin: 16 }}
        value={state}
      >
        <Column
          style={{ textAlign: "center" }}
          filterPlaceholder="Pesquise aqui"
          filter
          field="ra_aluno"
          header="RA"
        />
        <Column
          style={{ textAlign: "center" }}
          filterPlaceholder="Pesquise aqui"
          filter
          field="nome_aluno"
          sortable
          header="Nome Aluno"
        />

        <Column
          style={{ textAlign: "center" }}
          filterPlaceholder="Pesquise aqui"
          filter
          field="nome_disciplina"
          sortable
          header="Nome Disciplina"
        />
        <Column
          style={{ textAlign: "center" }}
          field="porcentagem_acertos"
          sortable
          header="% de Acertos"
        />
        <Column
          style={{ textAlign: "center" }}
          field="score_plagio"
          sortable
          header="% de Plágio"
        />
        <Column
          style={{ textAlign: "center", width: "15%" }}
          field="showModalButton"
          header="Ação"
        />
      </DataTable>
      <Dialog
        fullWidth
        style={{ textAlign: "center" }}
        open={modal.open}
        onClose={() => setModal({ ...modal, open: false })}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#eee"
          }}
        >
          <p>Resolução do aluno </p>
          <p>{modal.dataShow.nome_aluno}</p>
        </DialogTitle>
        <DialogContent style={{ textAlign: "justify" }}>
          <Card style={{ margin: 16 }}>
            <CardContent>
              <h5>Enunciado:</h5>
              <h6>{modal.dataShow.corpo_questao}</h6>
            </CardContent>
          </Card>
          <Card style={{ margin: 16 }}>
            <CardContent>
              <h5>Resumo:</h5>
              <div style={{ fontStyle: "italic" }}>
                {parse(modal.dataShow.resumo ? modal.dataShow.resumo : "")}
              </div>
            </CardContent>
          </Card>
          <Card style={{ margin: 16 }}>
            <CardContent>
              <h5>Introdução:</h5>
              <div style={{ fontStyle: "italic" }}>
                {parse(
                  modal.dataShow.introducao ? modal.dataShow.introducao : ""
                )}
              </div>
            </CardContent>
          </Card>
          <Card style={{ margin: 16 }}>
            <CardContent>
              <h5>Desenvolvimento:</h5>
              <div style={{ fontStyle: "italic" }}>
                {parse(
                  modal.dataShow.desenvolvimento
                    ? modal.dataShow.desenvolvimento
                    : ""
                )}
              </div>
            </CardContent>
          </Card>
          <Card style={{ margin: 16 }}>
            <CardContent>
              <h5>Consideracoes Finais:</h5>
              <div style={{ fontStyle: "italic" }}>
                {parse(
                  modal.dataShow.consideracoes_finais
                    ? modal.dataShow.consideracoes_finais
                    : ""
                )}
              </div>
            </CardContent>
          </Card>
          <Card style={{ margin: 16 }}>
            <CardContent>
              <h5>Referências:</h5>
              <div style={{ fontStyle: "italic" }}>
                {parse(
                  modal.dataShow.referencias ? modal.dataShow.referencias : ""
                )}
              </div>
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setModal({ ...modal, open: false })}
            variant="contained"
            color="primary"
            size="small"
            style={{ margin: 8 }}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        style={{ textAlign: "center" }}
        open={openPlagio}
        maxWidth="xl"
        fullWidth
        fullScreen
        onClose={() => setOpenPlagio(false)}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#eee"
          }}
        >
          <FileCopy htmlColor="#c49847" fontSize="large" />{" "}
          <p> ANÁLISE DE PLÁGIO</p>
          <p>
            Possibilidade de Plágio: {Math.round(resultPlagio.document_percent)}
            %
          </p>
        </DialogTitle>
        <DialogContent style={{ textAlign: "justify", display: "flex" }}>
          <div style={{ flex: 1, padding: "8px" }}>{resultPlagio.text}</div>
          <div style={{ flex: 1, padding: "8px" }}>
            {resultPlagio.results.map(result => {
              return (
                <PlagiumItem result={result.result} score={result.score} />
              );
            })}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenPlagio(false)}
            variant="contained"
            color="secondary"
            style={{ backgroundColor: "#c49847", margin: 8 }}
          >
            FECHAR
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
