import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import { Sigin as SigInView, Professor as ProfessorView, Tutor as TutorView, AVA } from './views';
import { CadastraQuestao, EditaQuestao, ListaQuestao } from './views/Professor/pages';
import ListagemGeral from './views/Tutor/Listagem/Geral';
import ListagemAbaixoDaMedia from './views/Tutor/Listagem/AbaixoDaMedia';

import { LoginAluno, RepondeQuestao } from './views/Aluno';

const Routes = () => {
	return (
		<Switch>
			<Redirect exact from="/" to="/sig-in" />
			{/* <Redirect exact from="/professor" to="/professor/list-question" /> */}

			<RouteWithLayout component={SigInView} exact layout={MinimalLayout} path="/sig-in" />

			<RouteWithLayout component={ListaQuestao} exact layout={MainLayout} path="/professor" />
			<RouteWithLayout component={CadastraQuestao} exact layout={MainLayout} path="/professor/add-question" />

			<RouteWithLayout component={ListagemAbaixoDaMedia} exact layout={MainLayout} path="/tutor" />
			<RouteWithLayout component={ListagemGeral} exact layout={MainLayout} path="/tutor/geral" />

			<RouteWithLayout component={AVA} exact layout={MainLayout} path="/ava" />

			<RouteWithLayout component={LoginAluno} exact layout={MinimalLayout} path="/aluno/sig-in" />
			<RouteWithLayout component={RepondeQuestao} exact layout={MainLayout} path="/aluno/atividade" />
			<RouteWithLayout component={LoginAluno} exact layout={MinimalLayout} path="/aluno/sig-in/:idQuestion" />

			<Redirect to="/aluno/sig-in/" />
		</Switch>
	);
};

export default Routes;
