import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';

import { Sidebar, Topbar, Footer } from './components';

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: 56,
		height: '100%',
		[theme.breakpoints.up('sm')]: {
			paddingTop: 64
		}
	},
	shiftContent: {
		paddingLeft: 240
	},
	content: {
		height: '100%'
	}
}));

const Main = (props) => {
	const { children } = props;
	const history = useHistory();
	const location = useLocation();
	useEffect(() => {
		const user = JSON.parse(sessionStorage.getItem('@discursive_uninta/d_u'));
		const user_aluno = JSON.parse(sessionStorage.getItem('@discursive_uninta/d_u_a'));
		if (user_aluno && /^\/aluno\//.test(location.pathname)) {
		} else if (user && /^\/tutor\//.test(location.pathname)) {
		} else if (user) {
			if (`/${user.funcao}` !== location) {
				history.push(user.funcao);
			}
		} else {
			history.push('/');
			console.log(location);
		}
	}, []);

	const classes = useStyles();
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
		defaultMatches: true
	});

	const [ openSidebar, setOpenSidebar ] = useState(
		location.pathname === '/ava' || /^\/aluno\//.test(location.pathname) ? false : true
	);

	const handleSidebarOpen = () => {
		setOpenSidebar(location.pathname === '/ava' || /^\/aluno\//.test(location.pathname) ? false : true);
	};

	const handleSidebarClose = () => {
		setOpenSidebar(false);
	};

	const shouldOpenSidebar = openSidebar;

	return (
		<div
			className={clsx({
				[classes.root]: true,
				[classes.shiftContent]:
					location.pathname === '/ava' || /^\/aluno\//.test(location.pathname) ? null : isDesktop
			})}
		>
			<Topbar onSidebarOpen={handleSidebarOpen} />

			<Sidebar
				onClose={handleSidebarClose}
				open={shouldOpenSidebar}
				variant={isDesktop ? 'persistent' : 'temporary'}
			/>

			<main className={classes.content}>
				{children}

				{/* <Footer /> */}
			</main>
		</div>
	);
};

Main.propTypes = {
	children: PropTypes.node
};

export default Main;
