import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Drawer } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { LiveHelp, AddBox, CreateTwoTone as Create, Block, TableChartOutlined } from '@material-ui/icons/';

import { SidebarNav } from './components';

const useStyles = makeStyles((theme) => ({
	drawer: {
		width: 240,
		[theme.breakpoints.up('lg')]: {
			marginTop: 64,
			height: 'calc(100% - 64px)'
		}
	},
	root: {
		backgroundColor: theme.palette.white,
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		padding: theme.spacing(2)
	},
	divider: {
		margin: theme.spacing(2, 0)
	},
	nav: {
		marginBottom: theme.spacing(2)
	}
}));

const Sidebar = (props) => {
	const location = useLocation();
	const { open, variant, onClose, className, ...rest } = props;

	const classes = useStyles();
	const user = JSON.parse(sessionStorage.getItem('@discursive_uninta/d_u'));
	const user_aluno = JSON.parse(sessionStorage.getItem('@discursive_uninta/d_u_a'));
	let funcao;
	if (user) {
		funcao = user.funcao;
	}
	if (user_aluno && /^\/aluno\//.test(location.pathname)) {
		funcao = 'aluno';
	}
	const pagesTutor = [
		{
			title: 'Reprovados',
			href: '/tutor',
			icon: <Block />
		},
		{
			title: 'Geral',
			href: '/tutor/geral',
			icon: <TableChartOutlined />
		}
	];
	const pagesAluno = [
		{
			title: 'Atividade',
			href: '/aluno/atividade',
			icon: <Create />
		}
	];
	const pagesProfessor = [
		{
			title: 'Minhas questões',
			href: '/professor',
			icon: <LiveHelp />
		},
		{
			title: 'Nova questão',
			href: '/professor/add-question',
			icon: <AddBox />
		}
	];

	return (
		<Drawer anchor="left" classes={{ paper: classes.drawer }} onClose={onClose} open={open} variant={variant}>
			<div {...rest} className={clsx(classes.root, className)}>
				<SidebarNav
					className={classes.nav}
					pages={
						funcao === 'professor' ? (
							pagesProfessor
						) : funcao === 'tutor' ? (
							pagesTutor
						) : funcao === 'aluno' ? (
							pagesAluno
						) : (
							[]
						)
					}
				/>
			</div>
		</Drawer>
	);
};

Sidebar.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func,
	open: PropTypes.bool.isRequired,
	variant: PropTypes.string.isRequired
};

export default Sidebar;
