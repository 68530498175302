import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
	TextField,
	Button,
	Card,
	FormControl,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio
} from '@material-ui/core/';
import { useSnackbar } from 'notistack';
import Lottie from 'react-lottie';

import palette from './../../theme/palette';
import { api } from '../../services';
import { book1 } from './../../assets/lottie';
import './sigin.css';

export default function Login({ history }) {
	const { enqueueSnackbar } = useSnackbar();

	const [ stateUser, setStateUser ] = useState('');
	const [ statePassword, setStatePassword ] = useState('');
	const [ stateTypeUser, setStateTypeUser ] = useState('');

	const location = useLocation();

	useEffect(() => {
		const user = JSON.parse(sessionStorage.getItem('@discursive_uninta/d_u'));
		if (user) {
			history.push(user.funcao);
		}
	}, []);
	const handleLogin = async () => {
		if (stateUser && statePassword && stateTypeUser) {
			api
				.post('/user/auth', { matricula: stateUser, senha: statePassword, funcao: stateTypeUser })
				.then(async ({ data }) => {
					sessionStorage.setItem('@discursive_uninta/d_u', JSON.stringify(data)); //d_u = data user
					const { funcao } = await data;
					console.log(funcao);
					history.push(funcao).catch((err) => {
						showMensage('Erro ao redirecionar, usuário inválido', 'error');
					});
				})
				.catch((error) => {
					if (error.response) {
						showMensage(error.response.data.error, 'error');
					}
				});
		} else {
			showMensage('Preencha corretamente o formulário de login.', 'error');
		}
	};

	const showMensage = (mensagem, variant = 'success') => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(mensagem, { variant });
	};

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: book1,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};
	return (
		<main id="main">
			<div className="container-fluid" id="container-fluid">
				<div className="row" id="row">
					<aside id="aside" className="col s12 m4">
						<h1 id="h1">Acesse</h1>
						<Card id="card">
							<form
								style={{
									display: 'flex',
									flexDirection: 'column',
									padding: 16,
									justifyContent: 'center'
								}}
							>
								<TextField
									id="user"
									label="Matrícula"
									onChange={(e) => {
										const value = e.target.value;
										setStateUser(value);
									}}
								/>
								<TextField
									id="password"
									label="Senha"
									type="password"
									onChange={(e) => {
										const value = e.target.value;
										setStatePassword(value);
									}}
								/>
								<FormControl id="formControlRadios" component="fieldset">
									<FormLabel component="legend">Tipo de usuario: </FormLabel>
									<RadioGroup
										onChange={({ target }) => {
											setStateTypeUser(target.value);
										}}
										row
										defaultValue=""
										aria-label="Tipo de usuario"
										name="customized-radios"
									>
										<FormControlLabel value="ava" control={<Radio />} label="AVA" />
										<FormControlLabel value="professor" control={<Radio />} label="Professor" />
										<FormControlLabel value="tutor" control={<Radio />} label="Tutor" />
									</RadioGroup>
								</FormControl>
								<Button
									color="primary"
									className="red darken-4"
									style={{ marginTop: 16 }}
									onClick={(e) => {
										e.preventDefault();
										handleLogin();
									}}
									variant="contained"
								>
									Entrar
								</Button>
							</form>
						</Card>
						.
					</aside>
					<article id="article" className="col s12 m8">
						<header id="header">
							<h3 id="h3">
								<span>Portal de</span>
								<br /> Avaliações Discursiva
							</h3>
						</header>
						<figure>
							<Lottie options={defaultOptions} />
						</figure>
						<footer style={{ marginBottom: 16, fontFamily: 'Arial' }}>
							<small>
								© DIRIN - PROEAD - <b style={{ color: palette.primary.dark }}>AIAMIS</b>
							</small>
						</footer>
					</article>
				</div>
			</div>
		</main>
	);
}
