import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions,
  Container,
  Grid,
  CardActions
} from "@material-ui/core";
import { Editor } from "primereact/editor";
import { useSnackbar } from "notistack";
import { api } from "./../../../../services";
import {
  ErrorOutline,
  CheckCircleOutline,
  FileCopy
} from "@material-ui/icons/";
import parse from "html-react-parser";
import PlagiumItem from "./components/PlagiumItem";
import styles from "./styles.module.css";

export default function RepondeQuestao() {
  const { enqueueSnackbar } = useSnackbar();
  function showMensage(mensagem, variant = "success") {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(mensagem, { variant });
  }

  const [textResumoState, setTextResumo] = useState({
    value: "",
    qntCaracteres: 1
  });
  const [textIntroducaoState, setTextIntroducao] = useState({
    value: "",
    qntCaracteres: 1
  });
  const [textDesenvolvimentoState, setTextDesenvolvimento] = useState({
    value: "",
    qntCaracteres: 1
  });
  const [textConsideracoesFinaisState, setTextConsideracoesFinais] = useState({
    value: "",
    qntCaracteres: 1
  });
  const [textReferenciaState, setTextReferencia] = useState({
    value: "",
    qntCaracteres: 1
  });
  const [questaoState, setQuestao] = useState("");
  const [alunoState, setAluno] = useState("");
  const [submetido, setSubmetido] = useState({
    isSubmetido: false,
    submissao: {}
  });

  const [modal, setModal] = useState({
    open: false,
    openSubmetido: false,
    messageSubmetido: "",
    message: ""
  });

  const [idPlagio, setIdPlagio] = useState("");
  const [statusPlagio, setStatusPlagio] = useState("");
  const [resultPlagio, setResultPlagio] = useState({ results: [] });
  const [openPlagio, setOpenPlagio] = useState(false);

  useEffect(() => {
    const dataQuestao = JSON.parse(
      sessionStorage.getItem("@discursive_uninta/d_q")
    );
    const dataAluno = JSON.parse(
      sessionStorage.getItem("@discursive_uninta/d_u_a")
    );

    verificaRespondido(dataQuestao, dataAluno).then(async response => {
      if (response.data.id_questao) {
        setSubmetido({ isSubmetido: true, submissao: response.data });
        if (response.data.id_plagio) {
          const plagio = await api.post("aluno/get-plagio", {
            id: response.data.id_plagio
          });
          setResultPlagio(plagio.data);
          setIdPlagio(response.data.id_plagio);
          setStatusPlagio("tatatat");
        }
      }
    });
    setQuestao({ ...dataQuestao });

    setAluno({ ...dataAluno });
  }, []);
  useEffect(() => {
    (async function() {
      if (idPlagio) {
        const plagio = await api.post("aluno/get-plagio", {
          id: idPlagio
        });
        setResultPlagio(plagio.data);
        setStatusPlagio(plagio.data.status);
      }
    })();
  }, [statusPlagio]);

  const analisaDesenvolvimento = async () => {
    const { data } = await api.post("/aluno/avaliar-texto", {
      id_questao: questaoState.id_questao,
      desenvolvimento: textDesenvolvimentoState.value
    });

    return data.porcentagem;
  };

  async function verificaRespondido1() {
    await api
      .post("/aluno/find-by-id-questao", {
        ra_aluno: alunoState.ra,
        id_questao: questaoState.id_questao
      })
      .then(response => {
        if (response.data.id_questao) {
          setSubmetido({ isSubmetido: true, submissao: response.data });
        }
      })
      .catch(() => {});
  }
  async function verificaRespondido(questao, aluno) {
    return await api.post("/aluno/find-by-id-questao", {
      ra_aluno: aluno.ra,
      id_questao: questao.id_questao
    });
  }
  const handleSubmete = async () => {
    if (
      textResumoState.value &&
      textIntroducaoState.value &&
      textDesenvolvimentoState.value &&
      textConsideracoesFinaisState.value &&
      textReferenciaState.value
    ) {
      const porcentagem = await analisaDesenvolvimento();
      setModal({
        ...modal,
        open: true,
        message:
          porcentagem >= 70 ? (
            <div>
              <p>
                Seu trabalho contempla os conceitos necessários para o
                desenvolvimento da discussão proposta pelo professor. Atendendo
                à <b> {porcentagem}% </b>dos critérios solicitados
              </p>

              <p>
                Você pode continuar editando sua dissertação clicando no botão
                TENTAR NOVAMENTE.
              </p>
              <br />
              <p>
                Caso deseje submeter o seu texto, clique no botão SUBMETER.{" "}
                <br />{" "}
                <b>Se for submetido, NÃO poderá ser editado novamente.</b>
              </p>
            </div>
          ) : (
            <div>
              <p>
                Prezado Estudante seu trabalho{" "}
                <b style={{ color: "#900" }}>não contempla</b> os conceitos
                necessários para a discussão da problemática proposta pelo
                professor. Atendendo à{" "}
                <b> {porcentagem ? porcentagem : "0"}% </b> dos critérios
                solicitados. Favor reveja o seu trabalho ou esclareça suas
                Dúvidas sobre o conteúdo no Fórum de Dúvidas com o Professor.
              </p>
              <br />
              <p>
                Você pode continuar editando seu texto clicando no botão TENTAR
                NOVAMENTE.
              </p>
              <br />
              <p>
                Caso deseje submeter o seu texto, clique no botão SUBMETER.{" "}
                <br />{" "}
                <b>Se for submetido, NÃO poderá ser editado novamente.</b>
              </p>
            </div>
          )
      });
    } else {
      showMensage("Preencha corretamente todos os campos!", "error");
    }
  };
  const handleSubmeterModal = async () => {
    if (
      textResumoState.value &&
      textIntroducaoState.value &&
      textDesenvolvimentoState.value &&
      textConsideracoesFinaisState.value &&
      textReferenciaState.value
    ) {
      const porcentagem = await analisaDesenvolvimento();
      await api
        .post("/submissao/", {
          id_questao: questaoState.id_questao,
          ra_aluno: alunoState.ra,
          nome_aluno: alunoState.name,
          resumo: textResumoState.value,
          introducao: textIntroducaoState.value,
          desenvolvimento: textDesenvolvimentoState.value,
          consideracoes_finais: textConsideracoesFinaisState.value,
          referencia: textReferenciaState.value,
          porcentagem_acertos: porcentagem,
          resultado: null
        })
        .then(response => {
          setModal({
            ...modal,
            openSubmetido: true,
            open: false,
            messageSubmetido:
              porcentagem >= 70 ? (
                <div>
                  Parabéns seu trabalho acaba de ser submetido favor retorne a
                  sala virtual da sua disciplina e responda a Autoavaliação da
                  Atividade discursiva.
                </div>
              ) : (
                <div>
                  Prezado estudante seu trabalho acaba de ser submetido,
                  <b>
                    {" "}
                    retorne a sala virtual da sua disciplina e responda a
                    Autoavaliação da Atividade discursiva.{" "}
                    <span style={{ color: "#900" }}>
                      Não esqueça de levar em consideração o seu desempenho
                    </span>{" "}
                    na realização da atividade proposta.
                  </b>
                </div>
              )
          });
          verificaRespondido1();
        })
        .catch(() => {
          showMensage("Erro ao salvar", "error");
        });
    } else {
      showMensage("Preencha corretamente todos os campos!", "error");
    }
  };
  const handleShowPlagio = async () => {
    setOpenPlagio(true);
  };
  return (
    <Container maxWidth="lg">
      <header
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <h4>Envio de Atividades Discursivas</h4>
        <h5>Discipina: {questaoState.nome_disciplina}</h5>
        {idPlagio && statusPlagio === "ready" && (
          <Grid md={4} sm={12}>
            <Card style={{ marginBottom: "8px", padding: 16 }}>
              <p>
                Caro aluno. Realizamos uma análise de similaridade no texto
                enviado por você, e identificamos que existe a similiaridade de{" "}
                <b>{Math.round(resultPlagio.document_percent)}%</b> com outros
                doumentos/sites.
              </p>
              <CardActions
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  onClick={handleShowPlagio}
                  className="btn-aluno"
                  variant="outlined"
                  color="primary"
                >
                  Ver Análise
                </Button>
              </CardActions>
            </Card>
          </Grid>
        )}
        <Card
          style={{
            marginLeft: 16,
            marginRight: 16,
            textAlign: "left",
            width: "100%"
          }}
        >
          <CardContent style={{}}>
            <h5 style={{ marginBottom: 32 }}>
              Esse é o espaço dedicado para a escrita, análise, e envio da sua
              atividade discursiva.
            </h5>
            <ul style={{ marginLeft: 32 }}>
              <li>
                1 - Escreva o seu texto, preenchendo os requisitos de formatação
                solicitados nos campos abaixo.
              </li>
              <li>
                2 - Clique em “Analisar” , seu texto será verificado
                considerando os conceitos-chaves definidos pelo professor e em
                seguida um feedback será apresentado com o seu resultado.
              </li>
              <li>
                3 - Procure identificar e incluir no texto, o número máximo de
                conceitos-chaves antes de enviar a atividade de forma definitiva
                e considere o seu desempenho na autoavaliação.
              </li>
              <li>4 - Caso seja necessário você poderá reescrevê-lo.</li>
              <li>
                5 - Após a análise clique em “Submeter” para enviar a versão
                final.
              </li>
            </ul>
          </CardContent>
        </Card>
      </header>

      <Card style={{ margin: 16 }}>
        <CardContent>
          <h5>Enunciado:</h5>
          <h6 style={{ textAlign: "justify", textIndent: "1.5cm" }}>
            {questaoState.corpo_questao}
          </h6>
        </CardContent>
      </Card>
      <Card style={{ margin: 16 }}>
        <CardContent>
          <section>
            <header
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end"
              }}
            >
              <h5>Introdução:</h5>
              <small
                style={{
                  display: submetido.isSubmetido ? "none" : "flex",
                  color: textResumoState.qntCaracteres > 251 ? "#f00" : "#000"
                }}
              >
                {textResumoState.qntCaracteres - 1}/250
              </small>
            </header>
            <article>
              {submetido.isSubmetido ? (
                <div style={{ fontStyle: "italic" }}>
                  {parse(
                    submetido.submissao.resumo ? submetido.submissao.resumo : ""
                  )}
                </div>
              ) : (
                <Editor
                  placeholder="Crie seu texto aqui."
                  style={{ height: "200px", fontSize: "12pt" }}
                  value={textResumoState.value}
                  onTextChange={e => {
                    setTextResumo({
                      value: e.htmlValue,
                      qntCaracteres: e.textValue.length
                    });
                    if (e.textValue.length > 251) {
                      showMensage(
                        "O Número de caracteres da introdução não pode exceder 250!",
                        "warning"
                      );
                    }
                  }}
                />
              )}
            </article>
            <footer />
          </section>
        </CardContent>
      </Card>
      <Card style={{ margin: 16 }}>
        <CardContent>
          <section>
            <header
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end"
              }}
            >
              <h5>Metodologia:</h5>
              <small
                style={{
                  display: submetido.isSubmetido ? "none" : "flex",
                  color:
                    textIntroducaoState.qntCaracteres > 501 ? "#f00" : "#000"
                }}
              >
                {textIntroducaoState.qntCaracteres - 1}/500
              </small>
            </header>
            <article>
              {submetido.isSubmetido ? (
                <div style={{ fontStyle: "italic" }}>
                  {parse(
                    submetido.submissao.introducao
                      ? submetido.submissao.introducao
                      : ""
                  )}
                </div>
              ) : (
                <Editor
                  placeholder="Crie seu texto aqui."
                  style={{ height: "200px", fontSize: "12pt" }}
                  value={textIntroducaoState.value}
                  onTextChange={e => {
                    setTextIntroducao({
                      value: e.htmlValue,
                      qntCaracteres: e.textValue.length
                    });
                    if (e.textValue.length > 501) {
                      showMensage(
                        "O Número de caracteres da metodologia não pode exceder 500!",
                        "warning"
                      );
                    }
                  }}
                />
              )}
            </article>
            <footer />
          </section>
        </CardContent>
      </Card>
      <Card style={{ margin: 16 }}>
        <CardContent>
          <section>
            <header
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end"
              }}
            >
              <h5>Resultados e Discussão:</h5>
              <small
                style={{
                  display: submetido.isSubmetido ? "none" : "flex",
                  color:
                    textDesenvolvimentoState.qntCaracteres > 2501
                      ? "#f00"
                      : "#000"
                }}
              >
                {textDesenvolvimentoState.qntCaracteres - 1}/2500
              </small>
            </header>
            <article>
              {submetido.isSubmetido ? (
                <div style={{ fontStyle: "italic" }}>
                  {parse(
                    submetido.submissao.desenvolvimento
                      ? submetido.submissao.desenvolvimento
                      : ""
                  )}
                </div>
              ) : (
                <Editor
                  placeholder="Crie seu texto aqui."
                  style={{ height: "350px", fontSize: "12pt" }}
                  value={textDesenvolvimentoState.value}
                  onTextChange={e => {
                    setTextDesenvolvimento({
                      value: e.htmlValue,
                      qntCaracteres: e.textValue.length
                    });
                    if (e.textValue.length > 2501) {
                      showMensage(
                        "O Número de caracteres dos resultados e discussão não pode exceder 2500!",
                        "warning"
                      );
                    }
                  }}
                />
              )}
            </article>
            <footer />
          </section>
        </CardContent>
      </Card>
      <Card style={{ margin: 16 }}>
        <CardContent>
          <section>
            <header
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end"
              }}
            >
              <h5>Considerações Finais:</h5>
              <small
                style={{
                  display: submetido.isSubmetido ? "none" : "flex",
                  color:
                    textConsideracoesFinaisState.qntCaracteres > 501
                      ? "#f00"
                      : "#000"
                }}
              >
                {textConsideracoesFinaisState.qntCaracteres - 1}/500
              </small>
            </header>
            <article>
              {submetido.isSubmetido ? (
                <div style={{ fontStyle: "italic" }}>
                  {parse(
                    submetido.submissao.consideracoes_finais
                      ? submetido.submissao.consideracoes_finais
                      : ""
                  )}
                </div>
              ) : (
                <Editor
                  placeholder="Crie seu texto aqui."
                  style={{ height: "200px", fontSize: "12pt" }}
                  value={textConsideracoesFinaisState.value}
                  onTextChange={e => {
                    setTextConsideracoesFinais({
                      value: e.htmlValue,
                      qntCaracteres: e.textValue.length
                    });
                    if (e.textValue.length > 501) {
                      showMensage(
                        "O Número de caracteres das considerações finais não pode exceder 500!",
                        "warning"
                      );
                    }
                  }}
                />
              )}
            </article>
            <footer />
          </section>
        </CardContent>
      </Card>
      <Card style={{ margin: 16 }}>
        <CardContent>
          <section>
            <header
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end"
              }}
            >
              <h5>Referências:</h5>
              <small
                style={{
                  display: submetido.isSubmetido ? "none" : "flex",
                  color:
                    textReferenciaState.qntCaracteres > 501 ? "#f00" : "#000"
                }}
              >
                {textReferenciaState.qntCaracteres - 1}/500
              </small>
            </header>
            <article>
              {submetido.isSubmetido ? (
                <div style={{ fontStyle: "italic" }}>
                  {parse(
                    submetido.submissao.referencias
                      ? submetido.submissao.referencias
                      : ""
                  )}
                </div>
              ) : (
                <Editor
                  max={250}
                  placeholder="Crie seu texto aqui."
                  style={{ height: "200px", fontSize: "12pt" }}
                  value={textReferenciaState.value}
                  onTextChange={e => {
                    setTextReferencia({
                      value: e.htmlValue,
                      qntCaracteres: e.textValue.length
                    });
                    if (e.textValue.length > 501) {
                      showMensage(
                        "O Número de caracteres das referências não pode exceder 500!",
                        "warning"
                      );
                    }
                  }}
                />
              )}
            </article>
            <footer />
          </section>
        </CardContent>
      </Card>

      <Card
        style={{
          display: submetido.isSubmetido ? "none" : "flex",
          margin: 16,
          justifyContent: "center"
        }}
      >
        <CardContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <section>
            <header>
              <h5 style={{ textAlign: "center" }}>Finalizar:</h5>
            </header>
            <article>
              <Button
                onClick={handleSubmete}
                variant="contained"
                color="primary"
                style={{ backgroundColor: "#049", margin: 8 }}
              >
                Analisar
              </Button>
            </article>
            <footer />
          </section>
        </CardContent>
      </Card>

      <Dialog
        style={{ textAlign: "center" }}
        open={modal.open}
        onClose={() => setModal({ ...modal, open: false })}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#eee"
          }}
        >
          <ErrorOutline fontSize="large" /> <p> ATENÇÃO!</p>
        </DialogTitle>
        <DialogContent>{modal.message}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => setModal({ ...modal, open: false })}
            variant="text"
            color="primary"
            style={{ margin: 8 }}
          >
            Tentar Novamente
          </Button>
          <Button
            onClick={handleSubmeterModal}
            variant="contained"
            color="secondary"
            style={{ backgroundColor: "#093", margin: 8 }}
          >
            Submeter
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        style={{ textAlign: "center" }}
        open={modal.openSubmetido}
        onClose={() => setModal({ ...modal, openSubmetido: false })}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#eee"
          }}
        >
          <CheckCircleOutline htmlColor="#0f0" fontSize="large" />{" "}
          <p> SUBMETIDO!</p>
        </DialogTitle>
        <DialogContent>{modal.messageSubmetido}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => setModal({ ...modal, openSubmetido: false })}
            variant="contained"
            color="secondary"
            style={{ backgroundColor: "#093", margin: 8 }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        style={{ textAlign: "center" }}
        open={openPlagio}
        maxWidth="xl"
        fullWidth
        fullScreen
        onClose={() => setOpenPlagio(false)}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#eee"
          }}
        >
          <FileCopy htmlColor="#c49847" fontSize="large" />{" "}
          <p> ANÁLISE DE PLÁGIO</p>
          <p>Possibilidade de Plágio: {resultPlagio.document_percent}%</p>
        </DialogTitle>
        <DialogContent style={{ textAlign: "justify", display: "flex" }}>
          <div style={{ flex: 1, padding: "8px" }}>{resultPlagio.text}</div>
          <div style={{ flex: 1, padding: "8px" }}>
            {resultPlagio.results.map(result => {
              return (
                <PlagiumItem result={result.result} score={result.score} />
              );
            })}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenPlagio(false)}
            variant="contained"
            color="secondary"
            style={{ backgroundColor: "#c49847", margin: 8 }}
          >
            FECHAR
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
