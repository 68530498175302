import React from "react";
import { Typography } from "@material-ui/core";

export default function PlagiumItem({ result, score }) {
  let color = "";
  if (score <= 30) {
    color = "#82f097";
  }
  if (score > 30 && score <= 60) {
    color = "#00c2eb";
  }
  if (score > 60 && score <= 80) {
    color = "#fea233";
  }
  if (score > 80) {
    color = "red";
  }
  return (
    <div
      style={{
        background: "#f0f0f0",
        marginBottom: 16,
        padding: 16
      }}
    >
      {result.obj_type === "document" ? (
        <Typography style={{ color: "#0055aa" }}>
          Foram encontradas semelhanças com outro documento enviado por outro
          aluno.
        </Typography>
      ) : (
        <a target="_blank" href={result.url}>
          <Typography style={{ color: "#0055aa" }}>{result.title}</Typography>
        </a>
      )}
      <p>{result.description}</p>
      <p>
        <span style={{ fontWeight: "500" }}>Similaridade:</span>
        <span
          style={{
            backgroundColor: color,
            borderRadius: "10%",
            padding: 4,
            fontSize: 10,
            marginLeft: 8,
            color: "white",
            fontWeight: "bold"
          }}
        >
          {Math.round(score)}%
        </span>
      </p>
    </div>
  );
}
