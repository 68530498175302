import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Hidden, IconButton } from '@material-ui/core';
import { ExitToApp, Menu } from '@material-ui/icons/';
import { useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	root: {
		boxShadow: 'none'
	},
	flexGrow: {
		flexGrow: 1
	},
	signOutButton: {
		marginLeft: theme.spacing(1)
	}
}));

const Topbar = (props) => {
	const { className, onSidebarOpen, ...rest } = props;

	const location = useLocation();
	const classes = useStyles();
	const history = useHistory();

	const user_aluno = JSON.parse(sessionStorage.getItem('@discursive_uninta/d_u_a'));
	let funcao = '';

	if (user_aluno && /^\/aluno\//.test(location.pathname)) {
		funcao = 'aluno';
	}

	return (
		<AppBar {...rest} className={clsx(classes.root, className)}>
			<Toolbar>
				<RouterLink to="/">
					<img alt="Logo" src="https://curadoria.aiamis.com.br/logo.png" />
				</RouterLink>
				<div className={classes.flexGrow} />
				{funcao && (
					<div style={{ display: 'flex', flexDirection: 'column-reverse', alignItems: 'flex-end' }}>
						<small>{user_aluno.name}</small>
						<small>{user_aluno.ra}</small>
					</div>
				)}

				<IconButton style={{ backgroundColor: 'transparent' }} color="inherit" onClick={onSidebarOpen}>
					<Menu />
				</IconButton>

				<IconButton
					color="inherit"
					onClick={() => {
						sessionStorage.clear();
						history.push('/');
					}}
				>
					<ExitToApp />
				</IconButton>
			</Toolbar>
		</AppBar>
	);
};

Topbar.propTypes = {
	className: PropTypes.string,
	onSidebarOpen: PropTypes.func
};

export default Topbar;
