import React, { useState } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import ChipInput from 'material-ui-chip-input';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import './styles.css';
import palette from './../../../../theme/palette';
import { api } from './../../../../services';

const StyledButton = withStyles({
	chip: { backgroundColor: '#09f', fontWeight: 500, fontSize: '12pt', color: '#fff' }
})(ChipInput);

export default function CadastraQuestao({ history }) {
	const user = JSON.parse(sessionStorage.getItem('@discursive_uninta/d_u'));

	const { enqueueSnackbar } = useSnackbar();
	const showMensage = (mensagem, variant = 'success') => {
		// variant could be success, error, warning, info, or default
		enqueueSnackbar(mensagem, { variant });
	};

	const [ questaoState, setQuestaoState ] = useState('');
	const [ nomeDisciplinaState, setNomeDisciplinaState ] = useState('');
	const [ idDisciplinaState, setIdDisciplinaState ] = useState('');
	const [ palavrasChaves, setPalavrasChaves ] = useState([]);

	const handleAddChip = (chip) => {
		setPalavrasChaves([ ...palavrasChaves, chip ]);
	};
	const handleDeleteChip = (chip) => {
		let keyWords = palavrasChaves.filter((item) => {
			return item !== chip;
		});
		setPalavrasChaves(keyWords);
	};
	const handleSalvaNoBanco = async (e) => {
		e.preventDefault();
		if (questaoState && palavrasChaves.length > 0 && nomeDisciplinaState && idDisciplinaState) {
			api
				.post('/questao', {
					id_usuario: user.id_usuario,
					corpo_questao: questaoState,
					palavras_chave: palavrasChaves,
					nome_disciplina: nomeDisciplinaState,
					id_disciplina: idDisciplinaState
				})
				.then((response) => {
					const data = response.data;
					if (data.success) {
						showMensage('Salvo com sucesso!');
						setQuestaoState('');
						setPalavrasChaves([]);
						setNomeDisciplinaState('');
						history.push('/professor');
					}
				})
				.catch((err) => {
					console.log(err.response);
				});
		} else {
			showMensage('Preencha todos os campos!', 'error');
		}
	};

	return (
		<main id="main" className="container">
			<header>
				<h4>{'CADASTRO'}</h4>
			</header>
			<section className="card">
				<div className="row">
					<span className="col s12 m3" style={{ paddingLeft: 0 }}>
						<label className="labels" htmlFor="id_disciplina">
							Id da Disciplina:
						</label>
						<InputText
							id="id_disciplina"
							value={idDisciplinaState}
							onChange={(e) => setIdDisciplinaState(e.target.value)}
						/>
					</span>
					<span className="col s12 m9">
						<label className="labels" htmlFor="nome_disciplina">
							Nome da disciplina:
						</label>
						<InputText
							id="nome_disciplina"
							value={nomeDisciplinaState}
							onChange={(e) => setNomeDisciplinaState(e.target.value)}
						/>
					</span>
				</div>
				<span className="row">
					<label className="labels" htmlFor="textArea">
						Enunciado da questão:
					</label>
					<InputTextarea
						autoResize={false}
						row={12}
						id="textArea"
						className="col s12"
						value={questaoState}
						onChange={(e) => setQuestaoState(e.target.value)}
					/>
				</span>
				<div className="row" style={{ marginTop: 16 }}>
					<span style={{ display: 'flex', flexDirection: 'column' }}>
						<label className="labels" htmlFor="chip">
            Digite os conceitos que deverão ser abordados na atividade proposta ( separe com <b>";"</b> )
						</label>
						<StyledButton
							id="chip"
							style={{ backgroundColor: '#fff', paddingTop: 16, paddingLeft: 8, paddingRight: 8 }}
							newChipKeys={[ ';', 'Enter' ]}
							value={palavrasChaves}
							onAdd={(chip) => handleAddChip(chip)}
							onDelete={(chip) => handleDeleteChip(chip)}
							placeholder="Separe com &quot;;&quot;"
						/>
					</span>
				</div>
				<div className="row" style={{ marginTop: 16 }}>
					<Button
						onClick={handleSalvaNoBanco}
						variant="contained"
						style={{ backgroundColor: palette.success.main, color: 'white', width: '100%' }}
					>
						Salvar questão
					</Button>
				</div>
			</section>
		</main>
	);
}
