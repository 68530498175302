import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  TextField,
  Button,
  Card,
  Grid,
  CircularProgress
} from "@material-ui/core/";
import { lottie404, lottieLoading } from "./../../../../assets/lottie/";
import Lottie from "react-lottie";
import { useSnackbar } from "notistack";
import { api } from "./../../../../services/";

export default function Login() {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { idQuestion } = useParams();
  const [stateUser, setStateUser] = useState("");
  const [statePassword, setStatePassword] = useState("");
  const [idQuest, setIdQuest] = useState(2);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (idQuestion) {
      if (idQuestion.length === 10) {
        api
          .post("questao/auth-questao", { id_questao: idQuestion })
          .then(response => {
            if (response.data) {
              setIdQuest(1);
              sessionStorage.setItem(
                "@discursive_uninta/d_q",
                JSON.stringify(response.data)
              ); //d_q = data questao
              const user = JSON.parse(
                sessionStorage.getItem("@discursive_uninta/d_u_a")
              );
              if (user) {
                if (user.name && user.ra && user.email) {
                  history.push("/aluno/atividade");
                }
              }
            } else {
              setIdQuest(0);
            }
          })
          .catch(response => {
            setIdQuest(0);
          });
      } else {
        setIdQuest(0);
      }
    } else {
      setIdQuest(0);
    }
  }, []);
  const handleLogin = () => {
    if (stateUser && statePassword) {
      api
        .post("aluno/check", { user: stateUser, password: statePassword })
        .then(response => {
          if (response.data) {
            const { error, id, success } = response.data;
            if (success != 1) {
              showMensage(error, "error");
              return;
            }
            api
              .post("aluno/profile", { id: stateUser })
              .then(response => {
                if (response.data) {
                  sessionStorage.setItem(
                    "@discursive_uninta/d_u_a",
                    JSON.stringify(response.data)
                  ); //d_q_a = data user aluno
                  history.push("/aluno/atividade");
                }
              })
              .catch(e => {
                console.log(e);
                showMensage("Erro ao entrar 2", "error");
              })
              .finally(() => setLoading(false));
          }
        })
        .catch(() => {
          showMensage("Erro ao entrar", "error");
          setLoading(false);
        });
    } else {
      showMensage("Preencha todos os campos", "error");
    }
  };

  function showMensage(mensagem, variant = "success") {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(mensagem, { variant });
  }

  return (
    <main style={{ height: "100%" }}>
      <Grid
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
        container
      >
        <Grid item xs={12} md={4}>
          {idQuest === 2 ? (
            <LottieLoading />
          ) : idQuest ? (
            <aside>
              <h3 style={{ textAlign: "center" }}>Acesse</h3>
              <Card style={{ padding: 25 }}>
                <form
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 16,
                    justifyContent: "center"
                  }}
                >
                  <TextField
                    id="user"
                    label="Matrícula"
                    onChange={e => {
                      const value = e.target.value;
                      setStateUser(value);
                    }}
                  />
                  <TextField
                    id="password"
                    label="Senha do AVA:"
                    type="password"
                    onChange={e => {
                      const value = e.target.value;
                      setStatePassword(value);
                    }}
                  />
                  <Button
                    color="primary"
                    className="red darken-4"
                    style={{ marginTop: 16 }}
                    onClick={e => {
                      e.preventDefault();
                      setLoading(true);
                      handleLogin();
                    }}
                    variant="contained"
                  >
                    {loading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "Entrar"
                    )}
                  </Button>
                </form>
              </Card>
            </aside>
          ) : (
            <Lottie404 />
          )}
        </Grid>
      </Grid>
    </main>
  );
}

function Lottie404() {
  const options404 = {
    loop: true,
    autoplay: true,
    animationData: lottie404,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return <Lottie options={options404} />;
}
function LottieLoading() {
  const optionsLoading = {
    loop: true,
    autoplay: true,
    animationData: lottieLoading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return <Lottie options={optionsLoading} />;
}
